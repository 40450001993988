<template>
  <div class="outer-wrapper">
    <Icon
      type="md-arrow-dropright"
      class="menu-ico"
      v-show="!swValue"
      @click="() => (swValue = true)"
      :size="50"
    />
    <div class="mask-box" v-show="swValue">
      <div class="inner-wrapper">
          <Menu theme="dark" :open-names="['0','1']">
      <Submenu :name="ele.id" v-for="ele in menuData.filter(val=>val.ifShow)" :key="ele.id">
        <template slot="title">
          <Icon :type="ele.icon" />
          {{ele.text}}
        </template>
        <MenuItem :name="i.id" :to="{name:i.to}" v-for="i in ele.children.filter(val=>val.ifShow)" :key="i.id">{{i.text}}</MenuItem>
      </Submenu>
    </Menu>
      </div>
      <div class="rest" @click.stop="()=>swValue=false"></div>
    </div>
  </div>
</template>
<script>
let authority = [];
let authData = JSON.parse(localStorage.getItem("authData"));
if (authData) {
  authority = authData.barRights;
}
export default {
  name: "menuBar",
  data() {
    return {
      swValue: false,
      defaultMenuData: [
          {
          id: "0",
          text: "用户权限",
          ifShow:true,
          icon: "ios-people",
          children: [
            { text: "用户管理", id: "0-0", to: "userManage",ifShow:true, },
            { text: "权限管理", id: "0-1", to: "roleManage",ifShow:true, },
          ]
        },
        {
          id: "1",
          text: "网站数据",
          ifShow:true,
          icon: "ios-subway",
          children: [
            { text: "资质证书", id: "1-1", to: "certificateManage",ifShow:true, },
            { text: "发展历程", id: "1-2", to: "historyManage",ifShow:true, },
            { text: "新闻中心", id: "1-3", to: "newsManage",ifShow:true, },
            { text: "我们的服务", id: "1-4", to: "servicesManage",ifShow:true, },
            { text: "您的行业", id: "1-8", to: "industriesManage",ifShow:true, },
            { text: "文件中心", id: "1-5", to: "archiveManage",ifShow:true, },
            { text: "办事处", id: "1-6", to: "officesManage",ifShow:true, },
            { text: "菁英招募", id: "1-7", to: "jobsManage",ifShow:true, },
          ]
        },
        
      ]
    };
  },
  created() {
  },
  methods: {},
  computed:{
      menuData:function(){
          let newData = JSON.parse(JSON.stringify(this.defaultMenuData));
        // return newData;//测试
          if(authority.indexOf('super_all')!==-1){
              return newData;
          }else if(authority.length===0){
              return [];
          }else{
          for(let i=0;i<this.defaultMenuData.length;i++){
              for(let j=0;j<this.defaultMenuData[i].children.length;j++){
                  if(authority.indexOf(this.defaultMenuData[i].children[j].to)===-1){
                      newData[i].children[j].ifShow = false;
                  }else{
                    newData[i].children[j].ifShow = true;
                  }
              }
              if(newData[i].children.every(ele=>{return !ele.ifShow;})){
                  newData[i].ifShow = false;
              }else{
                newData[i].ifShow = true;
              }
          }
          return newData;
          }
      },
  }
};
</script>
<style scoped>
.inner-wrapper {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: #515a6e;
  z-index:128;
}

.mask-box {
  position: absolute;
  height: 100%;
  width: 100%;
}

.rest{
    position: absolute;
    height:100%;
    width:100%;
    z-index:127;
}

.menu-ico {
  position: absolute;
  left: 0;
  top: 40%;
}
</style>